<template>
  <div class="relative flex flex-col">
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.details')" class="section-title" />

      <LangDropdown
        v-if="currentTab === 'details'"
        :lang="lang"
        :texts="resource.texts"
        :endpoint="`${endpoint}.texts.delete`"
        attribute="training_id"
        :disabled="isActive"
        @update="onUpdateLang"
        @cleared="$emit('refresh')"
      />
    </div>

    <Form
      :id="id"
      class="z-10"
      :is-loading="isLoading"
      :data="data"
      @update:tab="currentTab = $event"
      @click:cancel="$emit('click:cancel')"
      @click:save="$emit('click:save', $event)"
      @refresh="$emit('refresh')"
    />

    <Logs :data="data" class="mt-8 z-0" hide-action />
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useTraining from "@/composables/useTraining";
import useLangDropdown from "@/composables/useLangDropdown";
import useTexts from "@/composables/useTexts";
import useOptions from "@/composables/useOptions";
// Components
import VTitle from "@/components/VTitle";
import LangDropdown from "@/components/LangDropdown";
import Logs from "@/components/Logs";
import Form from "./Form";

export default {
  components: {
    VTitle,
    Form,
    Logs,
    LangDropdown
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  emits: ["click:cancel", "click:save", "refresh"],
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Data
    const currentTab = ref("details");

    // Computed
    const data = computed(() => props.resource);

    const texts = computed(() => data.value.texts);

    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.details")} - ${t("app.trainings", 2)} - ${t("app.teaching")}`;

      const name = getText(data.value?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    const isActive = computed(() => {
      return data.value.status === ALL_OPTIONS.ACTIVE.value;
    });

    // Composables
    const { ALL_OPTIONS } = useOptions();
    const { endpoint, localizedFields } = useTraining();
    const { getText } = useTexts();
    const { lang, updateLang } = useLangDropdown({
      texts,
      localizedFields
    });

    return {
      documentTitle,
      currentTab,
      data,
      isActive,
      // useTraining
      endpoint,
      // useLangDropdown
      lang,
      onUpdateLang: updateLang
    };
  }
};
</script>
